// BlogPostImprover.js
import './BlogPostImprover.scss';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import {
  ThumbUpIcon,
  ThumbDownIcon,
  TrashIcon,
  EyeIcon,
  ExclamationIcon,
} from '@heroicons/react/outline';
import { InformationCircleIcon } from '@heroicons/react/solid';
import cx from 'classnames';
import { useAlert } from 'react-alert';
import { track } from '../../services/track';

import ComparisonView from './ComparisonView';
import ImprovementSummary from './ImprovementSummary';
import FormInput from '../../components/form/FormInput';
import LoadingIcon from '../../components/LoadingIcon';
import FormContainer from './FormContainer';
import FormLabel from './FormLabel';
import DeleteModal from './DeleteModal';
import useSentData from '../../services/useSentData';
import PreviewBlock from '../PostPreview/PreviewBlock';
import { numberToThousands } from '../../utils/common';
import NovaBlogBoosterAnimation from '../../components/NovaBlogBoosterAnimation';
import { ADDITION_KEYWORDS_KEY } from '../../components/KeywordsTagInput';
import { addSecondaryKeywords } from '../../store/actions/post_builder.actions';
import ContentSection from './ContentSection';
import {
  setGenerateFirstDraftField,
  setFirstDraft,
  GENERATE_FIRST_DRAFT_FIELDS,
} from '../../store/actions/generate-first-draft';
import { LABELS, getId, getWordsCount } from './utils';
import NovaRobot from '../../styles/images/generate-first-draft/nova-robot.png';
import { isolateError } from '../../utils/api';
import { Tooltip } from '@material-ui/core';
import store from '../../store';

// Main component
const BlogPostImprover = () => {
  const { draft, ...fields } = useSelector(state => state.generateFirstDraft);
  const dispatch = useDispatch();
  const history = useHistory();
  const { project_id } = useParams();
  const alert = useAlert();
  const request = useSentData();
  const state = store.getState();
  const user = state.auth.user;

  const [trashed, setTrashed] = useState(false);
  const [loading, setLoading] = useState({
    [GENERATE_FIRST_DRAFT_FIELDS.title]: false,
    [GENERATE_FIRST_DRAFT_FIELDS.outline]: false,
    draft: false,
    delete: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bodyState, setBodyState] = useState({});
  const [analysis, setAnalysis] = useState(null);
  const [activeTab, setActiveTab] = useState('summary');
  const [showModal, setShowModal] = useState(false);

  const [comparisonData, setComparisonData] = useState([]);
  const [improvementSummary, setImprovementSummary] = useState(null);
  const [showOnlyChanges, setShowOnlyChanges] = useState(false);

  const createIsDisabled = useMemo(() => {
    const entriesOfRequiredFields = Object.entries(fields).filter(
      ([key]) =>
        key !== 'title' &&
        key !== 'outline' &&
        key !== 'tone' &&
        key !== 'audience' &&
        key !== 'outline_id' &&
        key !== 'instructions' &&
        key !== 'brand_voice' &&
        key !== 'outlineType'
    );
    const fieldsValues = entriesOfRequiredFields.map(e => {
      const [, v] = e;
      return v;
    });
    const fieldsInvalid = !!fieldsValues.filter(item => !item).length;
    return loading.draft || fieldsInvalid;
  }, [loading.draft, fields]);

  const allowReset = useMemo(() => {
    const entriesOfRequiredFields = Object.entries(fields);
    const fieldsValues = entriesOfRequiredFields
      .filter(item => item[0] !== GENERATE_FIRST_DRAFT_FIELDS.tone)
      .map(e => {
        const [, v] = e;
        return v;
      });
    return !!fieldsValues.filter(item => item).length;
  }, [fields]);

  const draftWordsCount = useMemo(() => {
    if (!draft) return 0;
    const titleText =
      draft.body.find(b => b.type === 'Title')?.state?.text ?? '';
    const bodyText = draft.body.find(b => b.type === 'Body')?.state?.text ?? '';
    const words = getWordsCount(titleText) + getWordsCount(bodyText);
    track('Blog post improver', {
      Words: words,
    });

    return words;
  }, [draft]);

  const resetFields = () => {
    track('Blog post improver', { Clicked: 'reset' });
    const listOfFields = Object.keys(GENERATE_FIRST_DRAFT_FIELDS);
    listOfFields
      .filter(field => field !== 'tone')
      .forEach(field => {
        dispatch(
          setGenerateFirstDraftField(GENERATE_FIRST_DRAFT_FIELDS[field], '')
        );
      });
    dispatch(
      setGenerateFirstDraftField(
        GENERATE_FIRST_DRAFT_FIELDS.tone,
        'informative'
      )
    );
    localStorage.removeItem('blogImproverData');
  };

  const resetDraft = () => {
    dispatch(setFirstDraft(null));
    localStorage.removeItem('blogImproverData');
  };

  const isValidUrl = url => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    url = url.trim();
    try {
      new URL(url);
      return urlRegex.test(url);
    } catch (error) {
      return false;
    }
  };

  const handleCreateImprovePost = useCallback(async () => {
    const primaryTopic = fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic];
    const url = fields[GENERATE_FIRST_DRAFT_FIELDS.url];
    if (
      (user.subscription === undefined || user.subscription === null) &&
      !user.has_subscription &&
      !user.has_access
    ) {
      history.push(`/plans`);
      return;
    }
    if (!primaryTopic) {
      alert.error(`${LABELS.primary_topic} required!`);
      return;
    }
    if (!url || !isValidUrl(url)) {
      alert.error(`Valid ${LABELS.url} required!`);
      return;
    }

    setLoading(state => {
      const draft = { ...state };
      draft.draft = true;
      return draft;
    });

    try {
      const res = await request.send('/api/improve-blog-post', {
        phrase: primaryTopic,
        url,
      });
      track('blog post improver', {
        Clicked: 'improve blog post',
        Keyword: primaryTopic,
        URL: url,
      });
      const { content, message, success } = res;

      if (!content) {
        throw new Error(message);
      }

      const bodyState = {
        id: null,
        text: content.output.html,
        generation_id: content.generation_id,
        like_status: content.like_status,
        created_at: content.created_at,
      };

      const postBody = [
        {
          type: 'Title',
          state: { text: content.output.original_post_html.title ?? '' },
        },
        { type: 'HeroImage', state: { file: null } },
        { type: 'Body', state: bodyState },
      ];

      setBodyState(bodyState);

      let analysis_data = {
        volume: null,
        competition: null,
        results: null,
        difficulty: null,
        suggested_keywords: [],
        trends: null,
        top_questions: [],
        top_related: [],
        top_posts: [],
        database: 'us',
      };
      const semrushRes = await request.send('/api/semrush', {
        keyword_phrase: primaryTopic,
        database: 'us',
      });

      if (!semrushRes.isAxiosError) {
        analysis_data = semrushRes;
      }

      const resCreatePost = await request.send(
        `/api/projects/${project_id}/posts`,
        {
          phrase: primaryTopic,
          analysis_data,
        }
      );

      const { id: newPostId } = resCreatePost;
      if (!newPostId) {
        alert.error('Failed');
        return;
      }

      const generateKeywordsResponse = await request
        .send('/api/generate-keywords', {
          phrase: primaryTopic,
        })
        .catch();
      const keywordStrings = generateKeywordsResponse?.content ?? '';
      const topics = keywordStrings ? keywordStrings.split(',') : null;

      if (topics) {
        const additionKeywords = topics.map((topic, index) => ({
          id: new Date().getTime() + index,
          text: topic,
          selected: false,
        }));
        sessionStorage.setItem(
          `${ADDITION_KEYWORDS_KEY}_${newPostId}`,
          JSON.stringify(additionKeywords)
        );
        dispatch(addSecondaryKeywords(topics));
      }

      // Save the post with generated draft content
      await request.send(
        `/api/projects/${project_id}/posts/${newPostId}`,
        {
          body: postBody,
          category: 1,
          titles: '',
          topics,
        },
        'put'
      );

      const resPost = await request.send(
        `/api/projects/${project_id}/posts/${newPostId}`,
        null,
        'get'
      );

      if (success) {
        alert.success(message);
        dispatch(setFirstDraft({ id: newPostId, body: resPost?.body }));
        setAnalysis(content.output);
        // setComparisonData(content.output.comparison_data || []);
        // console.log('Setting comparison data:', content.output.comparison_data);
        // setImprovementSummary(content.output.improvement_summary);
        // setShowModal(true);

        // Store data in local storage
        const storageData = {
          project_id,
          newPostId,
          analysis: content.output,
          comparisonData: content.output.comparison_data || [],
          improvementSummary: content.output.improvement_summary,
          draft: { id: newPostId, body: resPost?.body },
          primaryTopic: fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic],
          blogPostUrl: fields[GENERATE_FIRST_DRAFT_FIELDS.url],
        };
        localStorage.setItem('blogImproverData', JSON.stringify(storageData));
      } else {
        alert.error(message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft.draft = false;
        return draft;
      });
    }
  }, [fields, dispatch, alert, request, project_id]);

  const handleAcceptChange = (sectionIndex, changeIndex) => {
    setComparisonData(prevData => {
      const newData = [...prevData];
      const section = newData[sectionIndex];
      const change = section.changes[changeIndex];

      if (change.type === 'insert') {
        section.original =
          section.original.slice(0, change.lineNumber) +
          change.content +
          section.original.slice(change.lineNumber);
      } else if (change.type === 'delete') {
        section.original =
          section.original.slice(0, change.lineNumber) +
          section.original.slice(change.lineNumber + change.content.length);
      }

      section.changes.splice(changeIndex, 1);
      return newData;
    });
  };

  const handleRejectChange = (sectionIndex, changeIndex) => {
    setComparisonData(prevData => {
      const newData = [...prevData];
      newData[sectionIndex].changes.splice(changeIndex, 1);
      return newData;
    });
  };

  const handleMergeContent = () => {
    const mergedContent = comparisonData
      .map(section => section.original)
      .join('\n\n');
    // Update the draft with the merged content
    dispatch(
      setFirstDraft({
        ...draft,
        body: [
          ...draft.body.slice(0, 2),
          {
            type: 'Body',
            state: {
              ...draft.body[2].state,
              text: mergedContent,
            },
          },
        ],
      })
    );
    setShowModal(false);
  };

  const toggleModal = () => {
    if (analysis) {
      setShowModal(prev => !prev);
    }
  };

  const handleGoToDashboard = () => {
    resetFields();
    resetDraft();
    setShowModal(false);
    setAnalysis(null);
    history.push(`/project/${project_id}/drafts`);
  };

  const handleGoToEditor = draftId => {
    resetFields();
    resetDraft();
    track('Blog post improver', { Clicked: 'go to editor' });
    history.push(`/project/${project_id}/post-builder/${draftId}`);
  };

  const handleDeleteDraftCtaClicked = () => {
    setShowDeleteModal(true);
    setShowModal(false);
  };

  const handleDeleteDraft = useCallback(async () => {
    setLoading(state => {
      const draft = { ...state };
      draft.delete = true;
      return draft;
    });
    track('Blog post improver', { Clicked: 'delete draft' });
    try {
      await request.send(
        `/api/projects/${project_id}/posts/${draft.id}`,
        null,
        'delete'
      );
      alert.success('Deleted');
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft.delete = false;
        return draft;
      });
      setShowDeleteModal(false);
      setTrashed(pre => !pre);
      dispatch(setFirstDraft(null));
      localStorage.removeItem('blogImproverData');
    }
  }, [draft, alert, project_id, request]);

  const updateLikeBlogBooster = status => {
    const method = 'post';
    const newBodyState = { ...bodyState };
    if (newBodyState.like_status) {
      newBodyState.like_status[0].status = status;
    } else {
      newBodyState.like_status = [{ status }];
    }
    track('Blog post improver', { Clicked: status === 1 ? 'like' : 'dislike' });
    setBodyState(newBodyState);
    const url = status
      ? `/api/like-blog-booster`
      : '/api/like-blog-booster-remove';
    const updatedData = { generation_id: bodyState.generation_id, status };
    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
          // Handle success if needed
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const getWordCount = () => {
    const outline = fields[GENERATE_FIRST_DRAFT_FIELDS.outline] || '';
    const outlineItems = outline.split('\n');
    let totalSubHeading = outlineItems.length || 7;
    const wordCount = 150 + totalSubHeading * 115;
    return numberToThousands(wordCount);
  };

  const parseHtmlContent = htmlString => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const sections = [];
    let currentSection = null;

    doc.body.childNodes.forEach(node => {
      if (node.nodeName === 'H2') {
        if (currentSection) {
          sections.push(currentSection);
        }
        currentSection = {
          title: node.textContent,
          content: '',
          subsections: [],
        };
      } else if (node.nodeName === 'H3' && currentSection) {
        currentSection.subsections.push({
          title: node.textContent,
          content: '',
        });
      } else if (currentSection) {
        if (currentSection.subsections.length > 0) {
          currentSection.subsections[
            currentSection.subsections.length - 1
          ].content += node.outerHTML || node.textContent;
        } else {
          currentSection.content += node.outerHTML || node.textContent;
        }
      }
    });

    if (currentSection) {
      sections.push(currentSection);
    }

    return sections;
  };
  const renderImprovedContent = () => {
    if (!analysis) return null;

    const parsedSections = parseHtmlContent(analysis.html);
    return (
      <div className="content-gaps">
        {parsedSections.map((section, index) => (
          <ContentSection
            key={index}
            title={section.title}
            subsections={
              section.subsections.length > 0
                ? section.subsections
                : [{ title: '', content: section.content }]
            }
          />
        ))}
      </div>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'summary':
        return <ImprovementSummary summary={improvementSummary} />;
      case 'content-gaps':
        return (
          <div className="content-gaps">
            {/* <h2>Content Gaps</h2> */}
            <div dangerouslySetInnerHTML={{ __html: analysis.html }} />
          </div>
        );
      case 'comparison':
        return (
          <ComparisonView
            comparisonData={comparisonData}
            showOnlyChanges={showOnlyChanges}
            onToggleShowOnlyChanges={() => setShowOnlyChanges(!showOnlyChanges)}
            onAcceptChange={handleAcceptChange}
            onRejectChange={handleRejectChange}
          />
        );
      default:
        return null;
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleCreateImprovePost();
    }
  };

  const animationRef = useRef(null);

  const scrollToAnimation = () => {
    if (animationRef.current) {
      animationRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    if (loading.draft) {
      scrollToAnimation();
    }
  }, [loading.draft]);

  useEffect(() => {
    return () => {
      resetDraft();
      resetFields();
      setShowModal(false);
      setAnalysis(null);
    };
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem('blogImproverData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setAnalysis(parsedData.analysis);

      // Format comparison data if it exists
      if (parsedData.comparisonData) {
        const formattedComparisonData = parsedData.comparisonData.map(
          section => ({
            original: section.original,
            improved: section.improved,
            changes: section.changes.map(change => ({
              type: change.type,
              content: change.improved || change.original,
              lineNumber: 0, // You might need to calculate this based on your data structure
            })),
          })
        );
        setComparisonData(formattedComparisonData);
      } else {
        setComparisonData([]);
      }

      setImprovementSummary(parsedData.improvementSummary);
      dispatch(setFirstDraft(parsedData.draft));

      // Set primary topic and blog post URL
      if (parsedData.primaryTopic) {
        dispatch(
          setGenerateFirstDraftField(
            GENERATE_FIRST_DRAFT_FIELDS.primary_topic,
            parsedData.primaryTopic
          )
        );
      }
      if (parsedData.blogPostUrl) {
        dispatch(
          setGenerateFirstDraftField(
            GENERATE_FIRST_DRAFT_FIELDS.url,
            parsedData.blogPostUrl
          )
        );
      }
    }
  }, [dispatch]);

  return (
    <div className="blog-post-improver flex h-screen bg-gray-100">
      <div className="flex-grow p-6 overflow-auto">
        <div className="max-w-4xl mx-auto">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
              <img src={NovaRobot} alt="Nova Robot" className="h-16 w-16" />
              <h1 className="text-3xl font-bold text-gray-800">Blog Booster</h1>
            </div>
            <button
              className="px-4 py-2 bg-white border border-purple-500 text-purple-500 rounded-md hover:bg-purple-50 transition-colors"
              onClick={handleGoToDashboard}
              disabled={loading.draft}
            >
              Go to Dashboard
            </button>
          </div>

          {/* How it works section */}
          <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
            <h2 className="text-xl font-semibold mb-4">How it works:</h2>
            <ol className="list-decimal list-inside space-y-2">
              <li>Enter your blog post's main topic</li>
              <li>Paste the URL of your existing blog post</li>
              <li>Click "Improve My Post"</li>
              <li>Review and edit the improved content</li>
              <li>Use the editor to finalize your post</li>
            </ol>
          </div>

          {/* Main form */}
          <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
            <form onSubmit={e => e.preventDefault()} onKeyDown={handleKeyDown}>
              <FormContainer>
                <FormLabel label={LABELS.primary_topic} required />
                <FormInput
                  id={getId(LABELS.primary_topic)}
                  className="w-full"
                  inputClassName="rounded-lg"
                  value={fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic]}
                  onChange={val => {
                    dispatch(
                      setGenerateFirstDraftField(
                        GENERATE_FIRST_DRAFT_FIELDS.primary_topic,
                        val
                      )
                    );
                  }}
                  inputProps={{
                    placeholder: 'how to start a blog',
                    onKeyDown: handleKeyDown,
                  }}
                />
              </FormContainer>
              <FormContainer>
                <FormLabel label={LABELS.url} required />
                <FormInput
                  id={getId(LABELS.url)}
                  className="w-full"
                  inputClassName="rounded-lg"
                  value={fields[GENERATE_FIRST_DRAFT_FIELDS.url]}
                  onChange={val => {
                    dispatch(
                      setGenerateFirstDraftField(
                        GENERATE_FIRST_DRAFT_FIELDS.url,
                        val
                      )
                    );
                  }}
                  inputProps={{
                    placeholder: 'https://www.mywebsite.com/my-blog-post',
                    onKeyDown: handleKeyDown,
                  }}
                />
              </FormContainer>
              <div className="flex justify-between mt-6">
                <button
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors"
                  onClick={resetFields}
                  disabled={!allowReset}
                >
                  Reset
                </button>
                <button
                  className="px-6 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
                  onClick={handleCreateImprovePost}
                  disabled={createIsDisabled}
                >
                  {loading.draft && (
                    <LoadingIcon className="mr-2 h-5 w-5 animate-spin" />
                  )}
                  <span>Improve My Post</span>
                </button>
              </div>
            </form>
          </div>

          {/* Results Section */}
          {draft && (
            <div className="bg-white rounded-lg shadow-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-gray-800">
                  Improved Content
                </h2>
                <div className="flex items-center space-x-2">
                  <button
                    className="px-3 py-1 text-sm bg-purple-100 text-purple-700 rounded-md hover:bg-purple-200 transition-colors"
                    onClick={() => handleGoToEditor(draft.id)}
                  >
                    Go to Editor
                  </button>
                  <Tooltip title="Like" arrow>
                    <button
                      onClick={() => updateLikeBlogBooster(1)}
                      className={`p-1 rounded-full hover:bg-gray-100 focus:outline-none ${
                        bodyState.like_status &&
                        bodyState.like_status[0]?.status === 1
                          ? 'text-green-500'
                          : 'text-gray-500'
                      }`}
                    >
                      <ThumbUpIcon className="h-5 w-5" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Dislike" arrow>
                    <button
                      onClick={() => updateLikeBlogBooster(2)}
                      className={`p-1 rounded-full hover:bg-gray-100 focus:outline-none ${
                        bodyState.like_status &&
                        bodyState.like_status[0]?.status === 2
                          ? 'text-red-500'
                          : 'text-gray-500'
                      }`}
                    >
                      <ThumbDownIcon className="h-5 w-5" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Delete" arrow>
                    <button
                      onClick={handleDeleteDraftCtaClicked}
                      className="p-1 rounded-full text-gray-500 hover:bg-gray-100 focus:outline-none"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </Tooltip>
                </div>
              </div>
              <div className="max-h-[calc(100vh-400px)] overflow-y-auto">
                {renderImprovedContent()}
              </div>
            </div>
          )}

          {/* Nova Blog Booster Animation */}
          {loading.draft && (
            <div ref={animationRef} className="mt-6">
              <NovaBlogBoosterAnimation />
            </div>
          )}

          {/* Important notice */}
          <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mt-6">
            <p className="font-bold">Important:</p>
            <p>
              AI writers are not meant to generate publish-ready content. We
              recommend you add your own experiences, opinions, and edits so
              your article is valuable to the reader and ranks better in Google.
              Google's latest update emphasizes experience in content.
            </p>
          </div>
        </div>
      </div>

      {/* Delete Modal */}
      <DeleteModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={handleDeleteDraft}
        loading={loading.delete}
        feature="Blog Booster content"
      />

      {/* Analysis Modal */}
      {showModal && analysis !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg w-11/12 max-w-4xl max-h-[90vh] overflow-hidden">
            <div className="p-6 border-b">
              <h2 className="text-2xl font-bold text-gray-800">
                Blog Post Improvement Analysis
              </h2>
            </div>
            <div className="p-6">
              <div className="flex space-x-4 mb-6">
                {['summary', 'content-gaps', 'comparison'].map(tab => (
                  <button
                    key={tab}
                    className={`px-4 py-2 rounded-md ${
                      activeTab === tab
                        ? 'bg-purple-600 text-white'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab.charAt(0).toUpperCase() +
                      tab.slice(1).replace('-', ' ')}
                  </button>
                ))}
              </div>
              <div className="max-h-[calc(90vh-250px)] overflow-y-auto">
                {renderTabContent()}
              </div>
            </div>
            <div className="p-6 border-t flex justify-end space-x-4">
              <button
                className="px-6 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
                onClick={handleMergeContent}
              >
                Merge Changes
              </button>
              <button
                className="px-6 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogPostImprover;
