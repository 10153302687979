import React, { useState, useEffect, useImperativeHandle, useRef } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { XIcon } from '@heroicons/react/outline';
import HistoryTab from './HistoryTab';
import KeywordsTab from './KeywordsTab';
import InstructionsTab from './InstructionsTab';
import SeoTab from './SeoTab';
import { getNewSeoProgress } from '../utils/PostBuilderUtils';
import PostBuilderUtils from '../utils/PostBuilderUtils';
import panelCollapse, {
  setSidePanelOpenTab,
  sidePanelTypes,
} from '../store/actions/side_panel.action';
import { connect } from 'react-redux';
import PostBuilderPlagiarismPanel from './PostBuilderPlagiarismPanel';
import AIWritingToolsTab from './AIWritingToolsTab';
import { sidePanelTabs } from '../store/reducers/side_panel.reducer';
import PostBuilderChatPanel from './PostBuilderChatPanel';
import PostBuilderFactTrackerPanel from './PostBuilderFactTrackerPanel';
import SupplementalContentTab from './SupplementalContentTab';

const SidePanel = React.forwardRef((props, ref) => {
  const [openTab, setOpenTab] = useState(0);
  const [topPosts, setTopPosts] = useState([]);
  const plagiarismRef = useRef();
  const [topics, setTopics] = useState();
  const [seoData, setSeoData] = useState();
  const [phrase, setPhrase] = useState();
  const [blocks, setBlocks] = useState(
    PostBuilderUtils.getBlocksByTemplate('standard')
  );
  const [histories, setHistories] = useState([]);
  const relatedKeywords = useSelector(state => state.post.relatedKeywords);
  const topQuestions = useSelector(state => state.post.topQuestions);
  const secondaryKeywords = useSelector(state => state.post.secondaryKeywords);
  const sidePanelOpenTab = useSelector(state => state.panel.openTab);
  const { seoProgress, seoColor } = getNewSeoProgress(seoData);
  const dispatch = useDispatch();

  const tabs = [
    {
      name: 'Instruct',
      component: (
        <InstructionsTab
          phrase={phrase}
          onPhraseChange={(newPhrase, newAnalysisData) => {
            setPhrase(newPhrase);
            setTopPosts(newAnalysisData.top_posts);
          }}
          changePostLoader={props.changePostLoader}
        />
      ),
    },
    {
      name: 'AI Writing Tools',
      component: <AIWritingToolsTab editorRef={props.editorRef} />,
    },
    {
      name: 'Keywords',
      component: (
        <KeywordsTab
          // relatedKeywords={relatedKeywords}
          phrase={phrase}
          // topQuestions={topQuestions}
          topics={topics}
          topPosts={topPosts}
          editorRef={props.editorRef}
        />
      ),
    },
    {
      name: 'SEO',
      component: (
        <SeoTab
          data={seoData}
          phrase={phrase}
          blocks={blocks}
          updateFeaturedImage={props.updateFeaturedImage}
          editorRef={props.editorRef}
        />
      ),
    },
    // Add the Promotional Content tab conditionally
    ...(blocks &&
    blocks.length > 2 &&
    blocks[2]?.state?.social_content &&
    Object.keys(blocks[2].state?.social_content).length > 0
      ? [
          {
            name: 'Promotional',
            component: (
              <SupplementalContentTab
                editorRef={props.editorRef}
                social_content={blocks[2].state?.social_content}
              />
            ),
          },
        ]
      : []),
    {
      name: 'History',
      component: (
        <HistoryTab
          editorRef={props.editorRef}
          histories={histories}
          updateFeaturedImage={props.updateFeaturedImage}
        />
      ),
    },
  ];

  function handleSelectionChangeFnc() {
    if (plagiarismRef?.current?.handleSelectionChangeFnc) {
      plagiarismRef.current.handleSelectionChangeFnc();
    }
  }

  useEffect(() => {
    if (sidePanelOpenTab) {
      setOpenTab(sidePanelOpenTab);
    }
    return dispatch(setSidePanelOpenTab(sidePanelTabs.INSTRUCT));
  }, [sidePanelOpenTab]);

  useImperativeHandle(ref, () => ({
    handleSelectionChange: () => {
      handleSelectionChangeFnc();
    },

    updateState: (state, data) => {
      switch (state) {
        // case 'relatedKeywords':
        //   setRelatedKeywords(data);
        //   break;
        // case 'topQuestions':
        //   setTopQuestions(data);
        //   break;
        case 'topics':
          setTopics(data);
          break;
        case 'seoData':
          setSeoData(data);
          break;
        case 'phrase':
          setPhrase(data);
          break;
        case 'topPosts':
          setTopPosts(data);
          break;
        case 'setBlocks':
          setBlocks(data);
          break;
        case 'setHistories':
          setHistories(data);
        default:
          break;
      }
    },
  }));

  function renderOpenTab() {
    return <>{tabs[openTab].component}</>;
  }

  if (props.sidePanelValue === sidePanelTypes.PLAGIARISM) {
    return (
      <PostBuilderPlagiarismPanel
        editorRef={props.editorRef}
        ref={plagiarismRef}
      />
    );
  } else if (props.sidePanelValue === sidePanelTypes.CHAT) {
    return <PostBuilderChatPanel editorRef={props.editorRef} />;
  } else if (props.sidePanelValue === sidePanelTypes.FACT_CHECKER) {
    return <PostBuilderFactTrackerPanel editorRef={props.editorRef} />;
  }

  return (
    <div
      className={props.isPanelCollapsed ? '' : 'side-bar-open'}
      id="side-panel"
    >
      <div className="w-full fixed h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
        <div className="p-6">
          <div className="flex items-center md:items-start justify-between">
            <div className="text-lg font-medium text-gray-900">Assistant</div>
            <div className="md:absolute sm:ml-112 h-1 sm:h-4 flex items-center">
              <button
                type="button"
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-purple-500"
                onClick={() => {
                  const panel =
                    props.isPanelCollapsed === false &&
                    props.sidePanelValue === sidePanelTypes.PLAGIARISM
                      ? false
                      : !props.isPanelCollapsed;
                  props.panelCollapse(sidePanelTypes.DEFAULT, panel);
                }}
              >
                <span className="sr-only">Close panel</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-200">
          <div className="px-5">
            <nav
              className="-mb-px flex space-x-5 overflow-x-auto w-full md:w-120"
              x-descriptions="Tab component"
            >
              {tabs.map((tab, i) => (
                <button
                  key={tab.name}
                  type="button"
                  className={classNames(
                    openTab === i
                      ? 'border-purple-500 text-purple-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap pb-4 px-0 border-b-2 font-medium text-sm'
                  )}
                  onClick={() => setOpenTab(i)}
                >
                  {tab.name}
                  {tab.name !== 'History' &&
                  tab.name !== 'Instruct' &&
                  tab.name !== 'AI Writing Tools' &&
                  tab.name !== 'Promotional' ? (
                    <span
                      className={classNames(
                        openTab === i
                          ? 'bg-purple-100 text-purple-600'
                          : 'bg-gray-100 text-gray-900',
                        'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                      )}
                    >
                      {tab.name === 'SEO'
                        ? seoProgress
                        : (relatedKeywords?.length ?? 0) +
                          (topQuestions?.length ?? 0) +
                          1 +
                          (secondaryKeywords?.length ?? 0)}
                    </span>
                  ) : null}
                </button>
              ))}
            </nav>
          </div>
        </div>
        {renderOpenTab()}
      </div>
    </div>
  );
});

function mapStateToProps(state) {
  return {
    isPanelCollapsed: state.panel.isPanelCollapsed,
    sidePanelValue: state.panel.sidePanelValue,
  };
}

export default connect(mapStateToProps, { panelCollapse }, null, {
  forwardRef: true,
})(SidePanel);
