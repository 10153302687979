import React, { useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useHistory, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import classNames from 'classnames';
import { DotsVerticalIcon } from '@heroicons/react/solid';

import DeletePostModal from './DeletePostModal';
import MovePostModal from './MovePostModal';
import TableContainer from '../../components/table/table-container';
import Table from '../../components/table/table';
import TableDataRow from '../../components/table/table-data-row';
import TableDataCell from '../../components/table/table-data-cell';
import TablePagination from '../../components/table/table-pagination';

import store from '../../store';
import { Checkbox } from '@material-ui/core';

const headers = ['Keyword', 'Generations', 'Last Edit', ''];

function OutlinesList({
  posts,
  callback,
  response,
  onToggleSelectItem,
  isBulkSelect,
  selectedItems,
  onToggleSelectAllItems,
}) {
  let history = useHistory();
  const state = store.getState();
  const user = state.auth.user;
  const [openDeleteModal, showDeleteModal] = useState(false);
  const [openMoveModal, showMoveModal] = useState(false);
  const [postData, setPostData] = useState({});
  const { project_id } = useParams();

  function closeMoveModal(moved) {
    if (moved) {
      callback();
    }
    showMoveModal(false);
    setPostData({});
  }

  function closeDeleteModal(deleted) {
    if (deleted) {
      callback();
    }
    showDeleteModal(false);
  }

  function showDetails(id) {
    history.push(`/project/${project_id}/outline-generator/${id}`);
  }

  const onChangeSelected = (id, e) => {
    e.preventDefault();
    onToggleSelectItem(id, e.target.checked);
  };

  function renderTableData() {
    return posts.map((post, index) => {
      const { id, phrase, outlines, edited_at } = post;
      const truncatedPhrase =
        phrase.length > 60 ? phrase.substring(0, 57) + '...' : phrase;
      return (
        <TableDataRow key={id} className="cursor-pointer">
          {/* Checkboxes */}
          {isBulkSelect && (
            <TableDataCell>
              <Checkbox
                checked={selectedItems.findIndex(item => item === id) >= 0}
                onChange={e => onChangeSelected(id, e)}
                className="custom-checkbox dashboard-item-checkbox"
              />
            </TableDataCell>
          )}
          <TableDataCell onClick={() => showDetails(id)}>
            {truncatedPhrase}
          </TableDataCell>
          <TableDataCell onClick={() => showDetails(id)}>
            {outlines.length}
          </TableDataCell>
          <TableDataCell
            className="text-gray-500"
            onClick={() => showDetails(id)}
          >
            <Moment fromNow>{edited_at}</Moment>
          </TableDataCell>
          {(user.is_owner || user.is_manager || user.is_editor) && (
            <TableDataCell className="text-right">
              <Menu as="div" className="static inline-block text-left">
                <div>
                  <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-10 origin-top-right absolute right-14 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {(user.is_owner || user.is_manager) && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                setPostData({ id, phrase });
                                showMoveModal(true);
                              }}
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block w-full text-left px-4 py-2 text-sm'
                              )}
                            >
                              Move
                            </button>
                          )}
                        </Menu.Item>
                      )}
                      {(user.is_owner || user.is_manager || user.is_editor) && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                setPostData({ id, phrase });
                                showDeleteModal(true);
                              }}
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block w-full text-left px-4 py-2 text-sm'
                              )}
                            >
                              Delete
                            </button>
                          )}
                        </Menu.Item>
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </TableDataCell>
          )}
        </TableDataRow>
      );
    });
  }

  return (
    <div>
      <DeletePostModal
        id={postData.id}
        name={postData.phrase}
        projectId={project_id}
        category={'outlines'}
        open={openDeleteModal}
        setOpen={closeDeleteModal}
      />
      <MovePostModal
        id={postData.id}
        name={postData.phrase}
        projectId={project_id}
        category={'outlines'}
        post={postData}
        open={openMoveModal}
        setOpen={closeMoveModal}
      />
      <div className="flex flex-col">
        <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <TableContainer>
            <Table
              headers={headers}
              isSelection={isBulkSelect}
              dataLength={posts.length}
              selectedItemsLength={selectedItems.length}
              onToggleSelectAll={onToggleSelectAllItems}
              renderData={renderTableData}
            />
            <TablePagination
              startIndex={(response.current_page - 1) * response.page_size + 1}
              endIndex={
                response.current_page * response.page_size < response.count
                  ? response.current_page * response.page_size
                  : response.count
              }
              total={response.count}
              isPreviousDisabled={!response.previous_page}
              isNextDisabled={!response.next_page}
              onPrevious={() => callback(response.previous_page)}
              onNext={() => callback(response.next_page)}
            />
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default OutlinesList;
