import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import useSentData from '../services/useSentData';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import moment from 'moment';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Checkbox } from '@material-ui/core';
import DeletePostModal from '../pages/Dashboard/DeletePostModal';
import MovePostModal from '../pages/Dashboard/MovePostModal';
import TableContainer from '../components/table/table-container';
import Table from '../components/table/table';
import TableDataRow from '../components/table/table-data-row';
import TableDataCell from '../components/table/table-data-cell';
import TablePagination from '../components/table/table-pagination';
import classNames from 'classnames';
import { PlusIcon } from '@heroicons/react/solid';

const GenerateSocialContentButton = ({ projectId }) => (
  <Link
    to={`/project/${projectId}/social-media-generator`}
    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-150 ease-in-out"
  >
    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
    Generate Social Content
  </Link>
);

// Add this helper function at the top of your file or in a separate utils file
const truncatePlatforms = (platforms, maxLength = 30) => {
  let result = platforms.join(', ');
  if (result.length <= maxLength) return result;

  while (result.length > maxLength - 3) {
    platforms.pop();
    result = platforms.join(', ') + '...';
  }
  return result;
};

function SocialContent() {
  const history = useHistory();
  const { project_id } = useParams();
  const request = useSentData();
  const alert = useAlert();

  const [posts, setPosts] = useState([]);
  const [isBulkSelect, setIsBulkSelect] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDeleteModal, showDeleteModal] = useState(false);
  const [openMoveModal, showMoveModal] = useState(false);
  const [postData, setPostData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchSocialContents();
    setIsBulkSelect(false);
    setSelectedItems([]);
  }, []);

  const fetchSocialContents = (page = 1) => {
    setIsLoading(true);
    request
      .send(`/api/projects/${project_id}/posts/social-content`, null, 'get', {
        page: page,
      })
      .then(data => {
        if (!data.isAxiosError) {
          setPosts(data);
        } else {
          alert.error('Failed to fetch social contents');
          setPosts({ results: [] });
        }
      })
      .catch(() => {
        alert.error('An error occurred while fetching social contents');
        setPosts({ results: [] });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeMoveModal = moved => {
    if (moved) {
      fetchSocialContents();
    }
    showMoveModal(false);
    setPostData({});
  };

  const closeDeleteModal = deleted => {
    if (deleted) {
      fetchSocialContents();
    }
    showDeleteModal(false);
  };

  const showDetails = useCallback(
    id => {
      history.push(`/project/${project_id}/social-media-generator/${id}`);
    },
    [history, project_id]
  );

  const onChangeSelected = (id, e) => {
    e.preventDefault();
    const newSelectedItems = e.target.checked
      ? [...selectedItems, id]
      : selectedItems.filter(item => item !== id);
    setSelectedItems(newSelectedItems);
  };

  const onToggleSelectAllItems = checked => {
    setSelectedItems(checked ? posts.results.map(post => post.id) : []);
  };

  const handleBulkSelect = isSelected => {
    setIsBulkSelect(isSelected);
    if (!isSelected) {
      setSelectedItems([]);
    }
  };

  const renderTableData = () => {
    if (!posts.results || posts.results.length === 0) {
      return (
        <TableDataRow>
          <TableDataCell
            colSpan={isBulkSelect ? 7 : 6}
            className="text-center py-4"
          >
            No social content found.
          </TableDataCell>
        </TableDataRow>
      );
    }

    return posts.results.map(post => {
      const { id, social_content } = post;
      const allPlatforms = social_content.reduce((acc, content) => {
        return [...new Set([...acc, ...Object.keys(content.output)])];
      }, []);
      const platforms = truncatePlatforms(allPlatforms);
      const latestContent = social_content[0];
      const content = latestContent?.output
        ? Object.values(latestContent.output)[0].slice(0, 50) + '...'
        : 'No content available';
      const platformCount = allPlatforms.length;
      const createdAt = latestContent?.created_at || post.edited_at;

      return (
        <TableDataRow
          key={id}
          className="cursor-pointer"
          onClick={() => {
            showDetails(id);
          }}
        >
          {isBulkSelect && (
            <TableDataCell onClick={e => e.stopPropagation()}>
              <Checkbox
                checked={selectedItems.findIndex(item => item === id) >= 0}
                onChange={e => onChangeSelected(id, e)}
                className="custom-checkbox dashboard-item-checkbox"
              />
            </TableDataCell>
          )}
          <TableDataCell className="w-100" onClick={() => showDetails(id)}>
            <div className="w-100">
              <div className="truncate w-full">{content}</div>
            </div>
          </TableDataCell>
          <TableDataCell onClick={() => showDetails(id)}>
            {platforms}
          </TableDataCell>
          {/* <TableDataCell onClick={() => showDetails(id)}>
            {platformCount}
          </TableDataCell> */}
          <TableDataCell onClick={() => showDetails(id)}>
            {social_content.length}
          </TableDataCell>
          <TableDataCell
            className="text-gray-500"
            onClick={() => showDetails(id)}
          >
            {moment(createdAt).fromNow()}
          </TableDataCell>
          <TableDataCell className="text-right">
            <Menu as="div" className="static inline-block text-left">
              <div>
                <Menu.Button
                  className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"
                  onClick={e => e.stopPropagation()}
                >
                  <span className="sr-only">Open options</span>
                  <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-10 origin-top-right absolute right-14 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={e => {
                            e.stopPropagation();
                            setPostData({ id, phrase: '' });
                            showMoveModal(true);
                          }}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block w-full text-left px-4 py-2 text-sm'
                          )}
                        >
                          Move
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={e => {
                            e.stopPropagation();
                            setPostData({ id, phrase: '' });
                            showDeleteModal(true);
                          }}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block w-full text-left px-4 py-2 text-sm'
                          )}
                        >
                          Delete
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </TableDataCell>
        </TableDataRow>
      );
    });
  };

  const headers = ['Content', 'Platforms', 'Generations', 'Created At', ''];

  return (
    <NewWrapper
      header={<NewHeader />}
      pageHeading={
        <PageHeading
          title="📣 Social Media Content"
          category="social"
          gridView={false}
          selectedProjectId={project_id}
          isBulkSelect={isBulkSelect}
          setIsBulkSelect={setIsBulkSelect}
          selectedItems={selectedItems}
          onBulkDelete={() => {
            setPostData({ ids: selectedItems });
            showDeleteModal(true);
          }}
          actionButton={<GenerateSocialContentButton projectId={project_id} />}
        />
      }
    >
      {isLoading ? (
        <div className="text-center py-4">Loading...</div>
      ) : (
        <div className="w-full -mx-4 sm:-mx-6 lg:-mx-8">
          {' '}
          {posts?.results?.length ? (
            <div>
              <h4 className="mx-8 py-3 text-lg">Social Content</h4>
              <TableContainer className="w-full overflow-x-auto">
                <div className="inline-block min-w-full align-middle">
                  <Table
                    headers={headers}
                    isSelection={isBulkSelect}
                    dataLength={posts.results?.length || 0}
                    selectedItemsLength={selectedItems.length}
                    onToggleSelectAll={checked => {
                      const allIds = posts.results.map(post => post.id);
                      setSelectedItems(checked ? allIds : []);
                    }}
                    renderData={renderTableData}
                  />
                </div>
                <TablePagination
                  startIndex={(posts.current_page - 1) * posts.page_size + 1}
                  endIndex={
                    posts.current_page * posts.page_size < posts.count
                      ? posts.current_page * posts.page_size
                      : posts.count
                  }
                  total={posts.count}
                  isPreviousDisabled={!posts.previous_page}
                  isNextDisabled={!posts.next_page}
                  onPrevious={() => fetchSocialContents(posts.previous_page)}
                  onNext={() => fetchSocialContents(posts.next_page)}
                />
              </TableContainer>
            </div>
          ) : (
            <div className="posts-list drafts cards-wrapper">
              <h1 className="empty-message">
                You have no generated social content.
              </h1>
            </div>
          )}
          <DeletePostModal
            id={postData.id || postData.ids}
            name={'items'}
            projectId={project_id}
            category={'social content'}
            open={openDeleteModal}
            setOpen={closeDeleteModal}
            isBulk={Array.isArray(postData.ids)}
          />
          <MovePostModal
            id={postData.id}
            name={postData.phrase}
            projectId={project_id}
            category={'social content'}
            post={postData}
            open={openMoveModal}
            setOpen={closeMoveModal}
          />
        </div>
      )}
    </NewWrapper>
  );
}

export default SocialContent;
