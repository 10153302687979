import React, { useState, useEffect } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { ClipboardCopyIcon, PlusSmIcon } from '@heroicons/react/outline';
import Tooltip from '@material-ui/core/Tooltip';
import { useAlert } from 'react-alert';
import ReactMarkdown from 'react-markdown';

const SupplementalContentTab = ({ editorRef, social_content }) => {
  const [openSections, setOpenSections] = useState({});
  const alert = useAlert();

  useEffect(() => {
    // Set all sections to open by default
    const initialOpenSections = Object.keys(social_content).reduce(
      (acc, key) => {
        acc[key] = true;
        return acc;
      },
      {}
    );
    setOpenSections(initialOpenSections);
  }, [social_content]);

  const toggleSection = key => {
    setOpenSections(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text);
    alert.success('Successfully copied to clipboard!');
  };

  const insertIntoEditor = text => {
    if (editorRef?.current?.editor) {
      editorRef.current.editor.insertContent(text);
    }
  };

  const getTitleForKey = key => {
    const titleMap = {
      email: 'Email Newsletter',
      twitter: 'Twitter Post',
      youtube: 'YouTube Script',
      facebook: 'Facebook Post',
      linkedin: 'LinkedIn Post',
      instagram: 'Instagram Caption',
      pinterest: 'Pinterest Pin',
    };
    return titleMap[key] || key.charAt(0).toUpperCase() + key.slice(1);
  };

  const renderContentSection = (key, content) => {
    if (!content) return null;
    const title = getTitleForKey(key);

    return (
      <div key={key} className="mb-4">
        <div
          className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
          role="alert"
          onClick={() => toggleSection(key)}
        >
          {title}
          {openSections[key] ? (
            <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
          )}
        </div>
        {openSections[key] && (
          <div className="py-4 px-5">
            <div className="flex items-center space-x-4">
              <div className="min-w-0 text-sm flex-grow">
                <ReactMarkdown className="text-gray-900">
                  {content}
                </ReactMarkdown>
                <p className="text-xs text-gray-400 mt-2">
                  Character count: {content.length}
                </p>
              </div>
              <div className="flex items-center gap-4 flex-shrink-0">
                <Tooltip title="Insert in editor" arrow>
                  <button
                    onClick={() => insertIntoEditor(content)}
                    className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  >
                    <PlusSmIcon
                      className="h-4 w-4 text-gray-700"
                      aria-hidden="true"
                    />
                  </button>
                </Tooltip>
                <Tooltip title="Copy" arrow>
                  <button
                    onClick={() => copyToClipboard(content)}
                    className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  >
                    <ClipboardCopyIcon
                      className="h-4 w-4 text-gray-700"
                      aria-hidden="true"
                    />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="overflow-y-auto">
      <div className="space-y-4 w-80 sm:w-128">
        {social_content &&
          Object.entries(social_content).map(([key, value]) =>
            renderContentSection(key, value)
          )}
      </div>
    </div>
  );
};

export default SupplementalContentTab;
