import React, { useState, useEffect } from 'react';
import NovaRobot from '../styles/images/generate-first-draft/nova-robot.png';

const NovaResearchAnimation = () => {
  const [dots, setDots] = useState('');
  const [progress, setProgress] = useState(0);
  const [estimatedTime] = useState(() => Math.floor(Math.random() * 3) + 3); // Random number between 3 and 5

  useEffect(() => {
    const dotInterval = setInterval(() => {
      setDots(prev => (prev.length < 3 ? prev + '.' : ''));
    }, 500);

    const progressInterval = setInterval(() => {
      setProgress(prev => (prev < 100 ? prev + 1 : 0));
    }, (estimatedTime * 60 * 1000) / 100);

    return () => {
      clearInterval(dotInterval);
      clearInterval(progressInterval);
    };
  }, [estimatedTime]);

  return (
    <div className="nova-research-animation text-center p-6 bg-purple-100 rounded-lg shadow-lg">
      <img
        src={NovaRobot}
        alt="Nova Robot"
        className="w-24 h-24 mx-auto mb-4 animate-bounce"
      />
      <h3 className="text-xl font-bold text-purple-800 mb-2">
        Nova is conducting in-depth web research
      </h3>
      <p className="text-purple-600 mb-4">Creating your outline{dots}</p>
      <div className="w-full bg-purple-200 rounded-full h-2.5 mb-4">
        <div
          className="bg-purple-600 h-2.5 rounded-full transition-all duration-500 ease-out"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <p className="text-sm text-purple-700">
        Estimated time: {estimatedTime} minutes
      </p>
      <p className="text-sm text-red-500 mt-2">
        DO NOT refresh or leave this page.
      </p>
    </div>
  );
};

export default NovaResearchAnimation;
