import React, { useState, useEffect } from 'react';
import NovaRobot from '../styles/images/generate-first-draft/nova-robot.png';

const NovaBlogBoosterAnimation = () => {
  const [dots, setDots] = useState('');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const dotInterval = setInterval(() => {
      setDots(prev => (prev.length < 3 ? prev + '.' : ''));
    }, 500);

    const progressInterval = setInterval(() => {
      setProgress(prev => (prev < 100 ? prev + 1 : 0));
    }, 3600); // 6 minutes = 360 seconds, 360000ms / 100 = 3600ms per 1%

    return () => {
      clearInterval(dotInterval);
      clearInterval(progressInterval);
    };
  }, []);

  return (
    <div className="nova-blog-booster-animation text-center p-6 bg-purple-100 rounded-lg shadow-lg">
      <img
        src={NovaRobot}
        alt="Nova Robot"
        className="w-24 h-24 mx-auto mb-4 animate-bounce"
      />
      <h3 className="text-xl font-bold text-purple-800 mb-2">
        Take a moment to relax while
      </h3>
      <h3 className="text-xl font-bold text-purple-800 mb-4">
        Bramework improves your blog post
      </h3>
      <p className="text-purple-600 mb-4">Enhancing your content{dots}</p>
      <div className="w-full bg-purple-200 rounded-full h-2.5 mb-4">
        <div
          className="bg-purple-600 h-2.5 rounded-full transition-all duration-500 ease-out"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <p className="text-sm text-purple-700">Estimated time: 3-4 minutes</p>
      <p className="text-sm text-red-500 mt-2">
        DO NOT refresh or leave this page.
      </p>
    </div>
  );
};

export default NovaBlogBoosterAnimation;
