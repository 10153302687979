import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  NewspaperIcon,
  ChartBarIcon,
  HomeIcon,
  TemplateIcon,
  MenuAlt2Icon,
  KeyIcon,
  XIcon,
  PencilAltIcon,
  UsersIcon,
  ChevronDownIcon,
  CollectionIcon,
  ChevronDoubleLeftIcon,
  ChatAlt2Icon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  SwitchHorizontalIcon,
  SparklesIcon,
  AcademicCapIcon,
  HeartIcon,
  LightningBoltIcon,
  SupportIcon,
  CashIcon,
  BriefcaseIcon,
  ShareIcon,
} from '@heroicons/react/outline';
import { CheckIcon } from '@heroicons/react/solid';
import ReactTooltip from 'react-tooltip';

import ComponentTip from './ComponentTip';
import './ComponentTip.scss';
import dashboardLogo from '../styles/images/logo-purple-white-dashboard.svg';
import logoIconPurple from '../styles/images/logo-icon-purple.svg';
import { fetchProjects, selectProject } from '../store/actions/project.action';
import collapse from '../store/actions/menu.action';
import ChatLeftPanel from '../pages/Chat/ChatLeftPanel';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const defaultNavigation = [
  {
    name: 'Dashboard',
    href: '/',
    icon: HomeIcon,
    internal: true,
  },
  {
    name: 'Writing Assistant',
    href: '/',
    icon: PencilAltIcon,
    internal: true,
  },
  {
    name: 'Social Media',
    href: '/social-content',
    icon: ShareIcon,
    internal: true,
  },
  {
    name: 'Chat',
    href: '/',
    icon: ChatAlt2Icon,
    internal: true,
  },
  {
    name: 'Titles',
    href: '/',
    icon: NewspaperIcon,
    internal: true,
  },
  {
    name: 'Outlines',
    href: '/',
    icon: TemplateIcon,
    internal: true,
  },
  {
    name: 'Rewrites',
    href: '/',
    icon: SwitchHorizontalIcon,
  },
  {
    name: 'Summaries',
    href: '/',
    icon: SparklesIcon,
  },
  {
    name: 'Keyword Research',
    href: '/',
    icon: KeyIcon,
    internal: true,
  },
  {
    name: 'Performance',
    href: '/',
    icon: ChartBarIcon,
    internal: true,
  },
  {
    name: 'Tutorials',
    href:
      'https://www.youtube.com/playlist?list=PLM0EjlraSp3bwNspfBhMOBwD98x_Kenwv',
    icon: AcademicCapIcon,
    internal: false,
  },
];

function NewWrapper(props) {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [navigation, setNavigation] = useState(defaultNavigation);
  let { pathname } = useLocation();
  let history = useHistory();
  const dispatch = useDispatch();
  let isMenuCollapsed = useSelector(state => state.menu.isCollapsed);
  const isOnBoardingUser = sessionStorage.getItem('isOnBoardingUser');
  const isChatPage = pathname.startsWith('/chat');

  if (isChatPage) {
    isMenuCollapsed = false;
  }

  useEffect(() => {
    if (props.selectedProject) {
      let navigation = [
        {
          name: 'Dashboard',
          href: `/project/${props.selectedProject.id}/dashboard`,
          icon: HomeIcon,
          internal: true,
        },
        {
          name: 'Writing Assistant',
          href: `/project/${props.selectedProject.id}/drafts`,
          icon: PencilAltIcon,
          internal: true,
        },
        {
          name: 'Social Media',
          href: `/project/${props.selectedProject.id}/social-content`,
          icon: ShareIcon,
          internal: true,
        },
        {
          name: 'Chat',
          href: `/chat`,
          icon: ChatAlt2Icon,
          internal: true,
        },
        {
          name: 'Titles',
          href: `/project/${props.selectedProject.id}/titles`,
          icon: NewspaperIcon,
          internal: true,
        },
        {
          name: 'Outlines',
          href: `/project/${props.selectedProject.id}/outlines`,
          icon: TemplateIcon,
          internal: true,
        },
        {
          name: 'Rewrites',
          href: `/project/${props.selectedProject.id}/rewrites`,
          icon: SwitchHorizontalIcon,
          internal: true,
        },
        {
          name: 'Summaries',
          href: `/project/${props.selectedProject.id}/summaries`,
          icon: SparklesIcon,
          internal: true,
        },
        {
          name: 'Keyword Research',
          href: `/project/${props.selectedProject.id}/keywords`,
          icon: KeyIcon,
          internal: true,
        },
      ];
      if (
        (props.user.is_owner || props.user.is_manager) &&
        (props.user.app_sumo_subscription != null ||
          (props.user.subscription != null &&
            props.user.subscription.status === 'active'))
      ) {
        navigation = [
          ...navigation,
          {
            name: 'Performance',
            href: `/project/${props.selectedProject.id}/performance`,
            icon: ChartBarIcon,
            internal: true,
          },
        ];
      }
      navigation = [
        ...navigation,
        {
          name: 'Tutorials',
          href:
            'https://www.youtube.com/playlist?list=PLM0EjlraSp3bwNspfBhMOBwD98x_Kenwv',
          icon: AcademicCapIcon,
          internal: false,
        },
      ];
      if (isMenuCollapsed) {
        navigation = [
          ...navigation,
          {
            name: "What's New",
            href: 'https://www.bramework.com/cbxchangelog/release-notes/',
            icon: LightningBoltIcon,
            internal: false,
          },
          {
            name: 'Affiliate Program',
            href: 'https://bramework.firstpromoter.com/',
            icon: CashIcon,
            internal: false,
          },
        ];
      }

      setNavigation(navigation);
    } else {
      if (
        !props.projectLoading &&
        !props.projectError &&
        props.projects.length === 0
      ) {
        props.fetchProjects();
      }
    }
  }, [props, isMenuCollapsed]);

  const renderProjectMenu = () => {
    return (
      <Menu as="div" className="mt-4 px-3 relative inline-block text-left">
        <ComponentTip text={isMenuCollapsed ? 'Manage Projects' : ''}>
          <Menu.Button
            className={classNames(
              'group w-full bg-gray-100 rounded-md py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500',
              isMenuCollapsed ? 'px-0' : 'px-3.5'
            )}
          >
            <span
              className={classNames(
                'flex w-full items-center',
                isMenuCollapsed ? ' justify-center' : ' justify-between'
              )}
              onClick={() => {
                dispatch(collapse(false));
              }}
            >
              <span className="flex min-w-0 items-center justify-between space-x-3">
                <CollectionIcon className="w-6 h-6 flex-shrink-0" />
                {!isMenuCollapsed && (
                  <span className="flex-1 flex flex-col min-w-0">
                    <span className="text-gray-900 text-sm font-medium truncate">
                      {props.selectedProject
                        ? props.selectedProject.name
                        : 'Loading...'}
                    </span>
                    <span className="text-gray-500 text-sm truncate">
                      {props.selectedProject ? 'Project' : 'Please wait...'}
                    </span>
                  </span>
                )}
              </span>
              {!isMenuCollapsed && (
                <ChevronDownIcon
                  className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                  aria-hidden="true"
                />
              )}
            </span>
          </Menu.Button>
        </ComponentTip>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                    to={'/projects'}
                  >
                    Manage Projects
                  </Link>
                )}
              </Menu.Item>
            </div>
            <div className="py-1">
              {props.projects.length == 0 && props.projectLoading && (
                <Menu.Item>
                  <div className="text-gray-700 flex px-4 py-2 text-sm cursor-pointer">
                    Loading...
                  </div>
                </Menu.Item>
              )}
              {props.projects.map(project => (
                <Menu.Item key={`proj_sm_${project.id}`}>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'flex px-4 py-2 text-sm cursor-pointer'
                      )}
                      onClick={e => {
                        props.selectProject(project.id);
                        history.push(`/project/${project.id}/dashboard`);
                      }}
                    >
                      {props.selectedProject.id === project.id && (
                        <CheckIcon className="w-6 h-6 mr-2 text-green-600" />
                      )}
                      {project.name}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const renderNavigation = () => {
    return (
      <Fragment>
        {navigation.map(item => (
          <ComponentTip text={isMenuCollapsed ? item.name : ''}>
            {!item.internal ? (
              <a
                key={item.name}
                href={item.href}
                target="_blank"
                rel="noreferrer"
                className={classNames(
                  pathname === item.href
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  isMenuCollapsed ? 'justify-center' : ''
                )}
                data-toggle="tooltip"
                title="Tooltip on top"
              >
                <item.icon
                  className={classNames(
                    pathname === item.href
                      ? 'text-gray-300'
                      : 'text-gray-400 group-hover:text-gray-300',
                    'flex-shrink-0 h-6 w-6',
                    !isMenuCollapsed ? 'mr-3' : ''
                  )}
                  aria-hidden="true"
                  target="_blank"
                  rel="noreferrer"
                />

                {!isMenuCollapsed && item.name}
              </a>
            ) : (
              <Link
                key={item.name}
                className={classNames(
                  pathname === item.href
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  isMenuCollapsed ? 'justify-center' : ''
                )}
                to={item.href}
              >
                <item.icon
                  className={classNames(
                    pathname === item.href
                      ? 'text-gray-300'
                      : 'text-gray-400 group-hover:text-gray-300',
                    'flex-shrink-0 h-6 w-6',
                    !isMenuCollapsed ? 'mr-3' : ''
                  )}
                  aria-hidden="true"
                />
                {!isMenuCollapsed && item.name}
              </Link>
            )}
          </ComponentTip>
        ))}
        {isMenuCollapsed && (
          <ComponentTip text={isMenuCollapsed ? 'Help' : ''}>
            <a
              className={classNames(
                'group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-300 hover:bg-gray-700 hover:text-white cursor-pointer',
                isMenuCollapsed ? 'justify-center' : ''
              )}
              onClick={() => {
                window.Intercom('show');
              }}
            >
              <SupportIcon
                className={classNames(
                  'text-gray-400 group-hover:text-gray-300 flex-shrink-0 h-6 w-6',
                  !isMenuCollapsed ? 'mr-3' : ''
                )}
                aria-hidden="true"
              />
              {!isMenuCollapsed && <span>Help</span>}
            </a>
          </ComponentTip>
        )}
      </Fragment>
    );
  };

  const renderMobileSidebar = () => {
    return (
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4 cursor-pointer">
                <img
                  className="h-8 w-auto"
                  src={dashboardLogo}
                  alt="bramework"
                />
              </div>
              {isChatPage ? (
                <ChatLeftPanel />
              ) : (
                <>
                  {renderProjectMenu()}
                  <div className="mt-5 flex-1 h-0 overflow-y-auto">
                    <nav className="px-2 space-y-1">{renderNavigation()}</nav>
                    {!isMenuCollapsed && (
                      <div
                        style={{
                          marginTop: '2rem',
                          marginBottom: '2rem', // Added bottom margin
                          marginLeft: '1rem', // Added left margin
                          marginRight: '1rem', // Added right margin
                          borderRadius: '0.5rem',
                          border: '1px solid #9D59EF',
                          backgroundColor: 'rgba(136, 58, 225, 0.3)', // 30% opacity
                          overflow: 'visible', // Changed from 'hidden' to 'visible'
                        }}
                      >
                        <div style={{ padding: '1rem' }}>
                          <a
                            href="https://www.bramework.com/cbxchangelog/release-notes/"
                            target="_blank"
                            rel="noreferrer"
                            className="cursor-pointer text-white hover:text-gray-300"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: '1rem',
                              // color: 'white',
                              textDecoration: 'none',
                            }}
                          >
                            {/* Replace with your actual icon components */}
                            <LightningBoltIcon
                              style={{
                                height: '1.25rem',
                                width: '1.25rem',
                                // color: 'white',
                              }}
                            />
                            <span style={{ marginLeft: '0.75rem' }}>
                              What's New
                            </span>
                          </a>
                          <a
                            href="https://bramework.firstpromoter.com/"
                            target="_blank"
                            rel="noreferrer"
                            className="cursor-pointer text-white hover:text-gray-300"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: '1rem',
                              // color: 'white',
                              textDecoration: 'none',
                            }}
                          >
                            {/* Replace with your actual icon components */}
                            <CashIcon
                              style={{
                                height: '1.25rem',
                                width: '1.25rem',
                                // color: 'white',
                              }}
                            />
                            <span style={{ marginLeft: '0.75rem' }}>
                              Affiliate Program
                            </span>
                          </a>
                          <a
                            onClick={() => {
                              window.Intercom('show');
                            }}
                            className="cursor-pointer text-white hover:text-gray-300"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: '1.5rem',
                              // color: 'white',
                              textDecoration: 'none',
                            }}
                          >
                            {/* Replace with your actual icon components */}
                            <SupportIcon
                              style={{
                                height: '1.25rem',
                                width: '1.25rem',
                                // color: 'white',
                              }}
                            />
                            <span style={{ marginLeft: '0.75rem' }}>Help</span>
                          </a>
                          <Link
                            className="cursor-pointe bg-bw-hotPinkLight hover:bg-bw-hotPinkDark"
                            to={
                              props.user.app_sumo_subscription
                                ? [
                                    'bramework_tier1',
                                    'bramework_tier2',
                                    'bramework_tier3',
                                    'bramework_tier4',
                                  ].includes(
                                    props.user.app_sumo_subscription.plan
                                      .app_sumo_plan
                                  )
                                  ? '/appsumo-plan'
                                  : props.user.app_sumo_subscription.plan
                                      .app_sumo_plan === 'bramework_ltd1'
                                  ? '/bramework-ltd-plan'
                                  : props.user.app_sumo_subscription.plan
                                      .app_sumo_plan === 'free_user'
                                  ? '/account'
                                  : '/plans'
                                : '/plans'
                            }
                            style={{
                              display: 'block',
                              textAlign: 'center',
                              color: 'white',
                              // backgroundColor: '#9d58ef',
                              borderRadius: '0.375rem',
                              padding: '0.5rem',
                              textDecoration: 'none',
                              marginTop: '1rem', // Ensure there's space above the button
                              boxShadow: '0 0 5px rgba(157, 88, 239, 0.5)', // Added box-shadow property
                            }}
                          >
                            {(props.user.subscription === undefined ||
                              props.user.subscription === null) &&
                            !props.user.has_subscription &&
                            !props.user.app_sumo_subscription
                              ? 'Start trial for $1'
                              : props.user.has_trial_subscription &&
                                props.user.has_subscription
                              ? 'Start Plan Now'
                              : 'Upgrade'}
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderDesktopSidebar = () => {
    return (
      <div
        className={classNames(
          'hidden md:flex md:flex-col md:fixed md:inset-y-0',
          isMenuCollapsed ? 'md:w-16' : 'md:w-64'
        )}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
          <div
            className={classNames(
              'flex items-center h-16 flex-shrink-0 bg-gray-900 justify-between cursor-pointer',
              isMenuCollapsed ? 'px-2' : 'px-4'
            )}
          >
            <img
              className={classNames(
                'h-8 w-auto',
                isMenuCollapsed ? 'cursor-pointer' : ''
              )}
              src={isMenuCollapsed ? logoIconPurple : dashboardLogo}
              alt="bramework"
              onClick={() => {
                if (!isMenuCollapsed) return;
                dispatch(collapse(false));
              }}
            />
            {!isMenuCollapsed && !isChatPage && (
              <div
                className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md cursor-pointer p-2"
                onClick={() => dispatch(collapse(true))}
              >
                <ChevronDoubleLeftIcon className="w-6 " />
              </div>
            )}
            {isChatPage && (
              <div
                className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md cursor-pointer p-2"
                onClick={() => history.push('/')}
              >
                <XIcon className="w-6 " />
              </div>
            )}
          </div>
          {isChatPage ? (
            <ChatLeftPanel />
          ) : (
            <div className="flex-1 flex flex-col overflow-y-auto">
              {renderProjectMenu()}
              {/* Navigation */}
              <nav className="flex-1 px-2 py-4 space-y-1">
                {renderNavigation()}
              </nav>
              {!isMenuCollapsed && (
                <div
                  style={{
                    marginTop: '2rem',
                    marginBottom: '2rem', // Added bottom margin
                    marginLeft: '1rem', // Added left margin
                    marginRight: '1rem', // Added right margin
                    borderRadius: '0.5rem',
                    border: '1px solid #9D59EF',
                    backgroundColor: 'rgba(136, 58, 225, 0.3)', // 30% opacity
                    overflow: 'visible', // Changed from 'hidden' to 'visible'
                  }}
                >
                  <div style={{ padding: '1rem' }}>
                    <a
                      href="https://www.bramework.com/cbxchangelog/release-notes/"
                      target="_blank"
                      rel="noreferrer"
                      className="cursor-pointer text-white hover:text-gray-300"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1rem',
                        // color: 'white',
                        textDecoration: 'none',
                      }}
                    >
                      {/* Replace with your actual icon components */}
                      <LightningBoltIcon
                        style={{
                          height: '1.25rem',
                          width: '1.25rem',
                          // color: 'white',
                        }}
                      />
                      <span style={{ marginLeft: '0.75rem' }}>What's New</span>
                    </a>
                    <a
                      href="https://bramework.firstpromoter.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="cursor-pointer text-white hover:text-gray-300"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1rem',
                        // color: 'white',
                        textDecoration: 'none',
                      }}
                    >
                      {/* Replace with your actual icon components */}
                      <CashIcon
                        style={{
                          height: '1.25rem',
                          width: '1.25rem',
                          // color: 'white',
                        }}
                      />
                      <span style={{ marginLeft: '0.75rem' }}>
                        Affiliate Program
                      </span>
                    </a>
                    <a
                      onClick={() => {
                        window.Intercom('show');
                      }}
                      className="cursor-pointer text-white hover:text-gray-300"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1.5rem',
                        // color: 'white',
                        textDecoration: 'none',
                      }}
                    >
                      {/* Replace with your actual icon components */}
                      <SupportIcon
                        style={{
                          height: '1.25rem',
                          width: '1.25rem',
                          // color: 'white',
                        }}
                      />
                      <span style={{ marginLeft: '0.75rem' }}>Help</span>
                    </a>
                    <Link
                      className="cursor-pointe bg-bw-hotPinkLight hover:bg-bw-hotPinkDark"
                      to={
                        props.user.app_sumo_subscription
                          ? [
                              'bramework_tier1',
                              'bramework_tier2',
                              'bramework_tier3',
                              'bramework_tier4',
                            ].includes(
                              props.user.app_sumo_subscription.plan
                                .app_sumo_plan
                            )
                            ? '/appsumo-plan'
                            : props.user.app_sumo_subscription.plan
                                .app_sumo_plan === 'bramework_ltd1'
                            ? '/bramework-ltd-plan'
                            : props.user.app_sumo_subscription.plan
                                .app_sumo_plan === 'free_user'
                            ? '/account'
                            : '/plans'
                          : '/plans'
                      }
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        color: 'white',
                        // backgroundColor: '#9d58ef',
                        borderRadius: '0.375rem',
                        padding: '0.5rem',
                        textDecoration: 'none',
                        marginTop: '1rem', // Ensure there's space above the button
                        boxShadow: '0 0 5px rgba(157, 88, 239, 0.5)', // Added box-shadow property
                      }}
                    >
                      {(props.user.subscription === undefined ||
                        props.user.subscription === null) &&
                      !props.user.has_subscription &&
                      !props.user.app_sumo_subscription
                        ? 'Start trial for $1'
                        : props.user.has_trial_subscription &&
                          props.user.has_subscription
                        ? 'Start Plan Now'
                        : 'Upgrade'}
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <ReactTooltip
        place={'right'}
        class="tooltip-for-templates custom-tooltip-for-templates"
        type="light"
        effect="solid"
        html={true}
        border={false}
      />
      <div>
        {!isOnBoardingUser && renderMobileSidebar()}

        {/* Static sidebar for desktop */}
        {!isOnBoardingUser && renderDesktopSidebar()}

        {/* Topbar */}
        <div
          className={classNames(
            'flex flex-col',
            isOnBoardingUser ? '' : isMenuCollapsed ? 'md:pl-16' : 'md:pl-64'
          )}
        >
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
            {/* Sidebar show/hide button */}
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 md:hidden"
              onClick={() => {
                setSidebarOpen(true);
                dispatch(collapse(false));
              }}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            {props.header}
          </div>
          <main className="flex-1">
            <div className={isChatPage ? '' : 'py-6'}>
              {props.pageHeading}
              <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                {props.children}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
function mapStateToProps(state) {
  return {
    selectedProject: state.project.selectedProject,
    projects: state.project.projects,
    user: state.auth.user,
    projectLoading: state.project.loading,
    projectError: state.project.error,
    projectErrorMsg: state.project.errorMsg,
  };
}

export default connect(mapStateToProps, { fetchProjects, selectProject })(
  NewWrapper
);
