export function formatNumber(num, digits = 2) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

export function numberToThousands(num) {
  if (!num) {
    return '0';
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getQueryVariable(location, variable) {
  const query = location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export function cleanFileName(fileName) {
  var chars = { '-': ' ', _: ' ', '.': '' };
  var f = fileName.toLowerCase();
  var f = f
    .replace(/[-,_,.]/g, m => chars[m])
    .replace('jpeg', '')
    .replace('jpg', '')
    .replace('png', '')
    .replace('gif', '')
    .replace('svg', '');
  return f;
}

export function validBlogURL(str) {
  var pattern = new RegExp(
    '^((ft|htt)ps?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})' + // domain name and extension
    '(\\/[-a-z\\d%@_.~+&:/]+)' + // path
    '(\\?![;&a-z\\d%@_.,~+&:=-]*)?' + // query string
      '(\\#![-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return pattern.test(str);
}

export function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function removeTags(str) {
  if (str === null || str === '') return false;
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, '');
}

export function removeLineBreak(str) {
  if (str === null || str === '') return false;
  else str = str.toString();
  return str.replace(/(\r\n|\n|\r)/gm, '');
}

export function stripTags(str) {
  return str
    .replace(/(<(br[^>]*)>)/gi, '\n')
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/&nbsp;/g, '');
}

function rTrim(x, characters) {
  var start = 0;
  var end = x.length - 1;
  while (characters.indexOf(x[end]) >= 0) {
    end -= 1;
  }
  return x.substr(0, end + 1);
}

function lTrim(x, characters) {
  var start = 0;
  while (characters.indexOf(x[start]) >= 0) {
    start += 1;
  }
  var end = x.length - 1;
  return x.substr(start);
}

export function reformatHtml(hmtl) {
  var removeLeading = lTrim(hmtl, '<br />');
  var removeTrailing = rTrim(removeLeading, '<br /><br />');
  var remoaveBreaks = removeTrailing.replaceAll('<br /><br />', '</p><p>');
  return remoaveBreaks;
}

export function convertToPlain(html) {
  // Create a new div element
  var tempDivElement = document.createElement('div');

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || '';
}

// export const isMobile = () => {
//   const navigatorAgent =
//     navigator.userAgent || navigator.vendor || window.opera;
//   return (
//     /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
//       navigatorAgent
//     ) ||
//     /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ /])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
//       navigatorAgent.substr(0, 4)
//     )
//   );
// };

export const isMobile = {
  Android: function() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function() {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export function sortListByDate(data, property) {
  if (!data) {
    return null;
  }
  data.sort((a, b) => new Date(b[property]) - new Date(a[property]));
}

export const TONES = [
  { label: 'Appreciative', value: 'appreciative' },
  { label: 'Assertive', value: 'assertive' },
  { label: 'Casual', value: 'casual' },
  { label: 'Conversational', value: 'conversational' },
  { label: 'Convincing', value: 'convincing' },
  { label: 'Enthusiastic', value: 'enthusiastic' },
  { label: 'Exciting', value: 'exciting' },
  { label: 'Formal', value: 'formal' },
  { label: 'Funny', value: 'funny' },
  { label: 'Informative', value: 'informative' },
  { label: 'Inspirational', value: 'inspirational' },
  { label: 'Passionate', value: 'passionate' },
  { label: 'Positive', value: 'positive' },
  { label: 'Professional', value: 'professional' },
  { label: 'Negative', value: 'negative' },
  { label: 'Sarcastic', value: 'sarcastic' },
  { label: 'Urgent', value: 'urgent' },
  { label: 'Witty', value: 'Witty' },
];

export const NICHES = [
  { label: 'Business', value: 'business' },
  { label: 'Digital Marketing', value: 'digital-marketing' },
  { label: 'Food', value: 'Food' },
  { label: 'General', value: 'general' },
  { label: 'Healthcare', value: 'healthcare' },
  { label: 'Finance', value: 'finance' },
  { label: 'Real Estate', value: 'real-estate' },
  { label: 'Travel', value: 'travel' },
  { label: 'Technology', value: 'technology' },
];

export const OUTLINE_TYPES = [
  { label: 'Compare', value: 'compare' },
  { label: 'How to', value: 'how-to' },
  { label: 'Informational', value: 'informational' },
  { label: 'List', value: 'list' },
  { label: 'Tips', value: 'tips' },
];

export const LANGUAGES = [
  { label: 'Bulgarian', value: 'bulgarian' },
  { label: 'Chinese', value: 'chinese' },
  { label: 'Czech', value: 'czech' },
  { label: 'Danish', value: 'danish' },
  { label: 'Dutch', value: 'dutch' },
  { label: 'English', value: 'english' },
  { label: 'Estonian', value: 'estonian' },
  { label: 'Finnish', value: 'finnish' },
  { label: 'French', value: 'french' },
  { label: 'German', value: 'german' },
  { label: 'Greek', value: 'greek' },
  { label: 'Hungarian', value: 'hungarian' },
  { label: 'Italian', value: 'italian' },
  { label: 'Japanese', value: 'japanese' },
  { label: 'Latvian', value: 'latvian' },
  { label: 'Lithuanian', value: 'lithuanian' },
  { label: 'Polish', value: 'polish' },
  { label: 'Portuguese', value: 'portuguese' },
  { label: 'Romanian', value: 'romanian' },
  { label: 'Russian', value: 'russian' },
  { label: 'Slovak', value: 'slovak' },
  { label: 'Slovenian', value: 'slovenian' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'Swedish', value: 'swedish' },
];

export const LENGTHS = [
  { label: 'S', value: 0 },
  { label: 'M', value: 1 },
  { label: 'L', value: 2 },
];

export const truncateParagraph = (words = '', maxLength) => {
  if (!words) return '';
  const data = words.split(' ');
  const text = data.splice(0, maxLength).join(' ');
  return data.length > maxLength ? `${text}...` : text;
};

export function getDomainFromUrl(url) {
  let domain = url.replace(/^(https?:\/\/)?(www\.)?/, '');
  // Remove any path and query string
  domain = domain.split('/')[0];
  return domain;
}
