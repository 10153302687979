import React, { useState, useEffect } from 'react';
import './SummaryGenerator.scss';
import useSentData from '../services/useSentData';
import { isolateError } from '../utils/api';
import { useHistory, useParams } from 'react-router-dom';
import { track } from '../services/track';
import { useAlert } from 'react-alert';
import { TONES } from '../utils/common';
import { getId, LABELS } from './GenerateFirstDraft/utils';
import Select from '../components/form/FormSelect';
import FormContainer from './GenerateFirstDraft/FormContainer';
import FormLabel from './GenerateFirstDraft/FormLabel';
import FormTextarea from '../components/form/FormTextarea';
import LoadingIcon from '../components/LoadingIcon';
import NovaRobot from '../styles/images/generate-first-draft/nova-robot.png';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import { useSelector } from 'react-redux';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { Tooltip } from '@material-ui/core';
import { DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/outline';
import { ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/outline';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import FormInput from '../components/form/FormInput';

function Generator({ user }) {
  const request = useSentData();
  const history = useHistory();
  const alert = useAlert();
  const { id, project_id } = useParams();
  const [summaries, setSummaries] = useState([]);
  const [selectedRewrite, setSelectedRewrite] = React.useState();
  const [tone, setTone] = useState('informative');
  const [article, setArticle] = useState('');
  const [instructions, setInstructions] = useState('');

  const [loadingType, setLoadingType] = useState('');
  const [isQualityModalVisible, showQualityModal] = useState(false);
  const [analysisDataLoaded, setAnalysisDataLoaded] = useState(false);

  const [useBrandVoice, setUseBrandVoice] = useState(false);
  const currentProject = useSelector(state =>
    state.project.projects.find(p => p.id === parseInt(project_id))
  );
  const isUncategorizedProject =
    currentProject && currentProject.name === 'Uncategorized';
  const isBrandVoiceEmpty = !currentProject || !currentProject.brand_voice;
  const isDisabled = isUncategorizedProject || isBrandVoiceEmpty;

  const tooltipText = isUncategorizedProject
    ? 'Create a project folder to generate and use brand voice.'
    : isBrandVoiceEmpty
    ? 'Add or generate a brand voice in your project settings to use this feature.'
    : "Brand voice helps maintain consistency in your content's tone and style, aligning with your brand's personality and values.";

  const handleUseBrandVoiceChange = checked => {
    if (!isDisabled) {
      setUseBrandVoice(checked);
    }
  };

  const [inputType, setInputType] = useState('content');
  const [contentUrl, setContentUrl] = useState('');
  const [targetAudience, setTargetAudience] = useState('');

  useEffect(() => {
    if (id) {
      console.log('Fetching existing post, id:', id);
      request
        .send(`/api/projects/${project_id}/posts/${id}`, null, 'get')
        .then(data => {
          if (!data.isAxiosError) {
            setSummaries(data.summaries);
          } else {
            alert.error(isolateError(data));
          }
        });
    }
  }, []);

  useEffect(() => {
    if (analysisDataLoaded) {
      handleShowQualityModal();
    }
  }, [analysisDataLoaded]);

  function closeQualityModal() {
    showQualityModal(false);
  }

  function submitRequest() {
    track('Summary generator', { Clicked: 'generate' });
    setLoadingType('generator');
    request
      .send(`/api/summary-generator`, {
        original_text: inputType === 'content' ? article : '',
        content_url: inputType === 'url' ? contentUrl : '',
        tone,
        instructions,
        use_brand_voice: useBrandVoice,
        project_id,
        target_audience: targetAudience,
      })
      .then(response => {
        if (!response.isAxiosError) {
          setSummaries([...response.content, ...summaries]);
        } else {
          alert.error(isolateError(response));
        }
      })
      .finally(() => setLoadingType(''));
  }

  function submitPost() {
    let url = `/api/projects/${project_id}/posts`;
    let method = 'post';

    if (id) {
      url += `/${id}/summary-generator`;
      method = 'put';
    }
    setLoadingType('saveSummaries');
    request
      .send(
        url,
        {
          category: 5,
          summaries,
        },
        method
      )
      .then(() => {
        history.push(`/project/${project_id}/summaries`);
      });
  }
  function handleShowQualityModal() {
    showQualityModal(true);
  }

  const updateSummaryLikeStatus = (generation_id, status) => {
    const url = `/api/like-summary`;
    const method = 'post';
    const updatedData = { generation_id, status };

    request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
          const updatedSummaries = summaries.map(summary =>
            summary.generation_id === generation_id
              ? { ...summary, like_status: [{ status }] }
              : summary
          );
          setSummaries(updatedSummaries);
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const onDeleteSummary = (generation_id, index) => {
    const url = `/api/delete-summary`;
    const method = 'post';
    const updatedData = { generation_id };

    request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
          const newSummaries = summaries.filter(
            summary => summary.generation_id !== generation_id
          );
          setSummaries(newSummaries);
          alert.success('Summary deleted successfully!');
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const copyToClipboard = summary => {
    navigator.clipboard.writeText(summary.output?.text || summary.text || '');
    alert.success('Content copied to clipboard!');
  };

  const renderSummaryItem = (summary, index) => (
    <div key={summary.generation_id} className="bg-gray-50 p-4 rounded-lg mb-4">
      <div className="flex justify-between items-center mb-2">
        <span className="text-xs text-gray-400">
          {moment(summary.created_at).fromNow()}
        </span>
        <div className="flex items-center">
          <Tooltip title="Like" arrow>
            <button
              onClick={() => updateSummaryLikeStatus(summary.generation_id, 1)}
              className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-20"
            >
              <ThumbUpIcon
                className={`h-4 w-4 hover:text-green-500 ${
                  summary.like_status && summary.like_status[0]?.status === 1
                    ? 'text-green-500'
                    : 'text-gray-500'
                }`}
                aria-hidden="true"
              />
            </button>
          </Tooltip>
          <Tooltip title="Dislike" arrow>
            <button
              onClick={() => updateSummaryLikeStatus(summary.generation_id, 2)}
              className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-20"
            >
              <ThumbDownIcon
                className={`h-4 w-4 hover:text-red-500 ${
                  summary.like_status && summary.like_status[0]?.status === 2
                    ? 'text-red-500'
                    : 'text-gray-500'
                }`}
                aria-hidden="true"
              />
            </button>
          </Tooltip>
          <Tooltip title="Copy" arrow>
            <button
              onClick={() => copyToClipboard(summary)}
              className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-20"
            >
              <DocumentDuplicateIcon
                className="h-4 w-4 text-gray-500"
                aria-hidden="true"
              />
            </button>
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <button
              onClick={() => onDeleteSummary(summary.generation_id, index)}
              className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-20"
            >
              <TrashIcon className="h-4 w-4 text-gray-700" aria-hidden="true" />
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="bg-white p-3 rounded border border-gray-200">
        <ReactMarkdown className="prose prose-sm max-w-none">
          {summary.output?.text || summary.text || ''}
        </ReactMarkdown>
      </div>
    </div>
  );

  const isValidUrl = url => {
    const urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    return urlPattern.test(url);
  };

  const isFormValid = () => {
    if (inputType === 'url') {
      return isValidUrl(contentUrl.trim()) && contentUrl.trim().length >= 10;
    } else {
      return article.trim().length >= 10 && article.trim().length <= 10000;
    }
  };

  return (
    <NewWrapper header={<NewHeader />}>
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <div className="w-full md:w-1/3">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Summarizer
              </h3>
              <form className="space-y-6">
                <FormContainer>
                  <div className="flex items-center mb-4">
                    <input
                      type="checkbox"
                      id="use-brand-voice"
                      className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                      checked={useBrandVoice && !isDisabled}
                      onChange={e => {
                        if (!isDisabled) {
                          handleUseBrandVoiceChange(e.target.checked);
                        }
                      }}
                      disabled={isDisabled}
                    />
                    <label
                      htmlFor="use-brand-voice"
                      className={`ml-2 block ${
                        isDisabled ? 'text-gray-400' : 'text-gray-900'
                      }`}
                    >
                      Use Brand Voice
                    </label>
                    <Tooltip title={tooltipText}>
                      <InformationCircleIcon className="h-5 w-5 ml-2 text-gray-500" />
                    </Tooltip>
                  </div>
                  {isDisabled && (
                    <p className="mt-1 text-sm text-gray-500">
                      {isUncategorizedProject
                        ? 'Create a project folder to use the brand voice feature.'
                        : 'Add or generate a brand voice in your project settings.'}
                    </p>
                  )}
                </FormContainer>

                <div className="flex items-center justify-center space-x-4 mb-6">
                  <button
                    type="button"
                    onClick={() => setInputType('content')}
                    className={`px-4 py-2 text-sm font-medium rounded-md ${
                      inputType === 'content'
                        ? 'bg-purple-600 text-white'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    Content Text
                  </button>
                  <button
                    type="button"
                    onClick={() => setInputType('url')}
                    className={`px-4 py-2 text-sm font-medium rounded-md ${
                      inputType === 'url'
                        ? 'bg-purple-600 text-white'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    Content URL
                  </button>
                </div>

                {inputType === 'content' ? (
                  <FormContainer>
                    <div className="char-count-container mb-2">
                      <FormLabel label="Content Text" className="form-label" />
                      <div className="w-32 text-sm font-normal text-gray-500 text-right char-count">
                        {article.length} / 10000
                      </div>
                    </div>
                    <FormTextarea
                      value={article}
                      onChange={setArticle}
                      id="content-text"
                      hideCharCount
                      maxLength={10000}
                      textareaClassName={'resize-none h-auto'}
                      rows={10}
                      placeHolder="Enter the content you wish to summarize."
                    />
                  </FormContainer>
                ) : (
                  <FormContainer>
                    <FormLabel label="Content URL" />
                    <FormInput
                      id="content-url"
                      value={contentUrl}
                      onChange={setContentUrl}
                      required
                      inputProps={{
                        placeholder: 'https://www.example.com/your-content',
                      }}
                    />
                    {contentUrl.trim() !== '' &&
                      !isValidUrl(contentUrl.trim()) && (
                        <p className="mt-2 text-sm text-red-600">
                          Please enter a valid URL (e.g.,
                          https://www.example.com)
                        </p>
                      )}
                  </FormContainer>
                )}

                <FormContainer>
                  <FormLabel label="Target Audience" optional />
                  <FormInput
                    id="target-audience"
                    value={targetAudience}
                    onChange={setTargetAudience}
                    inputProps={{
                      placeholder:
                        'e.g., young professionals, parents, tech enthusiasts',
                    }}
                  />
                </FormContainer>

                <FormContainer>
                  <FormLabel label="Tone" />
                  <Select
                    id={getId(LABELS.tone)}
                    className="w-full"
                    options={TONES}
                    value={TONES.find(item => item.value === tone)}
                    onChange={setTone}
                  />
                </FormContainer>

                <FormContainer>
                  <div className="char-count-container mb-2">
                    <FormLabel
                      label="Instructions"
                      optional
                      className="form-label"
                    />
                    <div className="w-32 text-sm font-normal text-gray-500 text-right char-count">
                      {instructions.length} / 2000
                    </div>
                  </div>
                  <FormTextarea
                    value={instructions}
                    onChange={setInstructions}
                    id="instructions"
                    hideCharCount
                    maxLength={2000}
                    textareaClassName={'resize-none h-auto'}
                    rows={6}
                    placeHolder="Condense the content while preserving key points and maintaining the overall message of the original article."
                  />
                </FormContainer>

                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => submitPost()}
                    className={`inline-flex items-center px-4 py-2 border border-purple-600 text-sm font-medium rounded-md text-purple-600 bg-purple-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-300 transition-colors duration-150 ease-in-out
                      ${
                        request.loading ||
                        (summaries && !summaries.length) ||
                        loadingType === 'saveSummaries'
                          ? 'opacity-50 cursor-not-allowed'
                          : 'hover:bg-purple-200 cursor-pointer'
                      }`}
                    disabled={
                      request.loading ||
                      (summaries && !summaries.length) ||
                      loadingType === 'saveSummaries'
                    }
                  >
                    {loadingType === 'saveSummaries' && (
                      <LoadingIcon className="-ml-1 mr-3 h-5 w-5 text-purple-600" />
                    )}
                    {loadingType === 'saveSummaries'
                      ? 'Saving...'
                      : 'Save Summaries'}
                  </button>
                  <button
                    onClick={() => submitRequest()}
                    className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-300 transition-colors duration-150 ease-in-out
                      ${
                        request.loading || !isFormValid()
                          ? 'bg-purple-300 cursor-not-allowed'
                          : 'bg-purple-600 hover:bg-purple-700 cursor-pointer'
                      }`}
                    disabled={request.loading || !isFormValid()}
                  >
                    {loadingType === 'generator' && (
                      <LoadingIcon className="-ml-1 mr-3 h-5 w-5 text-white" />
                    )}
                    {loadingType === 'generator'
                      ? 'Generating...'
                      : 'Generate Summary'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full md:w-2/3">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Generated Summaries
              </h3>
              {summaries.length > 0 ? (
                <div className="space-y-6 max-h-screen overflow-y-auto">
                  {summaries.map((summary, index) =>
                    renderSummaryItem(summary, index)
                  )}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center h-64">
                  <div className="nova-robot-container">
                    <img
                      src={NovaRobot}
                      alt="nova-robot"
                      className="w-24 h-24 mb-4 nova-robot"
                    />
                  </div>
                  {loadingType === 'generator' ? (
                    <p className="text-gray-500 text-center loading-text">
                      Summarizing your content...
                      <br />
                      This may take a moment.
                    </p>
                  ) : (
                    <p className="text-gray-500 text-center">
                      Enter content and click "Generate Summary"
                      <br />
                      to create your first summary.
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </NewWrapper>
  );
}

export default Generator;
