import React from 'react';
import LoadingIcon from '../../components/LoadingIcon';

const FormatOutlineButton = ({
  loading = false,
  disabled = false,
  onFormat = () => {},
}) => {
  return (
    <button
      className="absolute bottom-6 right-4 text-xs font-semibold rounded-lg bg-gray-100 border border-gray-200 shadow-sm px-4 py-1 hover:bg-gray-200 flex items-center text-gray-500 disabled:cursor-not-allowed"
      disabled={loading || disabled}
      onClick={() => onFormat()}
    >
      {loading && <LoadingIcon />}
      <span>Format Outline</span>
    </button>
  );
};

export default FormatOutlineButton;
