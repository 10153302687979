import React from 'react';
import FormTextarea from '../../components/form/FormTextarea';

const UserProvidedOutline = ({
  value = '',
  onChange = () => {},
  id = '',
  rows = 8,
}) => {
  const placeholderText = `Enter your outline here. Use the following format:

H2: Main Heading 1
H3: Subheading 1.1
H3: Subheading 1.2
H2: Main Heading 2
H3: Subheading 2.1
H3: Subheading 2.2
H2: Conclusion

Example:
H2: Introduction to Gardening
H3: Benefits of Home Gardening
H3: Essential Gardening Tools
H2: Getting Started with Your Garden
H3: Choosing the Right Location
H3: Preparing the Soil
H2: Conclusion`;

  const handleChange = newValue => {
    onChange(newValue);
  };

  return (
    <div className="w-full my-2 relative">
      <FormTextarea
        value={value}
        onChange={handleChange}
        id={id}
        hideCharCount
        textareaClassName={'resize-none h-auto'}
        rows={rows}
        placeHolder={placeholderText}
      />
    </div>
  );
};

export default UserProvidedOutline;
