import React, { useEffect, useState, Fragment } from 'react';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import useSentData from '../services/useSentData';
import Loading from '../components/Loading';
import ProjectsList from './NewProject/ProjectsList';
import { connect } from 'react-redux';
import { selectProject, fetchProjects } from '../store/actions/project.action';
import { PlusIcon } from '@heroicons/react/solid';
import CreateProjectSlideOver from '../pages/NewProject/CreateProjectSlideOver';

function Projects(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.fetchProjects();
  }, []);

  const handleNewProjectClick = e => {
    e.preventDefault();
    setOpen(true);
  };

  const newProjectButton = (
    <button
      onClick={handleNewProjectClick}
      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-150 ease-in-out"
    >
      <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
      New Project
    </button>
  );

  return (
    <NewWrapper
      header={<NewHeader />}
      pageHeading={
        <PageHeading
          title="All Projects"
          category="project"
          actionButton={newProjectButton}
        />
      }
    >
      <CreateProjectSlideOver open={open} setOpen={setOpen} />
      {props.loading ? (
        <Loading />
      ) : props.projects.length ? (
        <ProjectsList
          projects={props.projects}
          selectProject={props.selectProject}
          selectedProject={props.selectedProject}
          fetchProjects={props.fetchProjects}
        />
      ) : (
        <div className="posts-list">
          <h1 className="empty-message">You have no projects created.</h1>
        </div>
      )}
    </NewWrapper>
  );
}

function mapStateToProps(state) {
  return {
    projects: state.project.projects,
    selectedProject: state.project.selectedProject,
    loading: state.project.loading,
  };
}

export default connect(mapStateToProps, { selectProject, fetchProjects })(
  Projects
);
