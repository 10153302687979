import React from 'react';
import NovaRobot from '../styles/images/generate-first-draft/nova-robot.png';

const DraftGenerationModal = ({
  isOpen,
  estimatedTime,
  onClose,
  onGoToDashboard,
  onCreateAnotherPost,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 draft-generation-modal">
      <div className="bg-white rounded-lg p-8 max-w-md w-full modal-content">
        <img
          src={NovaRobot}
          alt="Nova Robot"
          className="w-24 h-24 mx-auto mb-4 animate-bounce"
        />
        <h2 className="text-2xl font-bold text-purple-800 mb-4 text-center">
          Nova is writing your first draft!
        </h2>
        <p className="text-gray-600 mb-4 text-center">{estimatedTime}</p>
        <p className="text-gray-600 mb-4 text-center">
          You'll be notified by email when it's ready.
        </p>
        <div className="flex justify-center space-x-4">
          <button
            onClick={onGoToDashboard}
            className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
          >
            Go to Dashboard
          </button>
          <button
            onClick={onCreateAnotherPost}
            className="px-4 py-2 bg-bw-green text-white rounded-md hover:bg-bw-darkGreen transition-colors"
          >
            Create Another Post
          </button>
        </div>
      </div>
    </div>
  );
};

export default DraftGenerationModal;
